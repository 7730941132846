/* eslint-disable no-console */


const link = {
  dev: "http://be-tms.smtjetty.my.id/api",
  prod: "http://be-tms.smtjetty.my.id/api",
  map: "http://map-tms.smtjetty.my.id/index.html"
}

export default link
